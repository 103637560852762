import photoSchema from './photo'

const schema = {
  endpoint: '/api/galleries',
  resource: {
    definition: 'Gallery-gallery.read',
    properties: {
      title: {
        width: 600,
        validate: ['required']
      },
      year: {
        validate: ['required']
      },
      slug: {
        type: 'slug',
        routes: '/galeria/:slug',
        hint: 'Automatycznie generowany z tytułu',
        width: 300
      },
      stat: {}
    },
  },
  subresources: {
    photo: {
      endpoint: '/api/gallery_photos',
      definition: 'GalleryPhoto-galleryPhoto.read',
      properties: {
        title: {
          width: 600,
        },
        photo: {
          type: 'image',
          endpoint: photoSchema.endpoint.single,
          thumbs: {
            endpoint: '/api/gallery_photo_thumbs',
            names: {
              main: 'Kadr główny'
            }
          },
          validate: ['maxSize'],
        },
        stat: {},
      },
    }
  }
}

export default schema
