import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import schema from '_schema/authorityGroup'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Tytuł',
        accessor: 'title',
        filterable: true,
        sortable: true,
        width: '75%',
      },
      {
        ...operations('title'),
        width: '13%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle="Dodaj nową grupę"
        buttonPath={routes().new.path}
      >
        Lista władz oddziału
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
      />
    </Paper>
  )
}
