import home from 'pages/Home/routes'
import news from 'pages/News/routes'
import pages from 'pages/Pages/routes'
import galleries from 'pages/Galleries/routes'
import eventCalendars from 'pages/EventCalendars/routes'
import trails from 'pages/Trails/routes'
import badges from 'pages/Badges/routes'
import authorityGroups from 'pages/AuthorityGroups/routes'
import committees from 'pages/Committees/routes'
import postcards from 'pages/Postcards/routes'
import publications from 'pages/Publications/routes'
import admins from 'pages/Admins/routes'
import config from 'pages/Config/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(news()),
    ...getArray(pages()),
    ...getArray(galleries()),
    ...getArray(eventCalendars()),
    ...getArray(trails()),
    ...getArray(badges()),
    ...getArray(authorityGroups()),
    ...getArray(committees()),
    ...getArray(postcards()),
    ...getArray(publications()),
    ...getArray(config()),
    ...getArray(admins()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(profile()),
  ]

const getArray = routes => Object.values(routes)

export default routes
