import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'pages'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/trail'
import routes from './routes'

const useStyles = makeStyles({
  embedded: {
    marginTop: 20
  }
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const classes = useStyles()

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
    >
      <div className={classes.embedded}>
        <EmbeddedCollection
          endpoint={schema.subresources.description.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.description.properties}
          definitionSchema={definitions[schema.subresources.description.definition]}
          headerTitle="Opisy"
          panelTitle="Opis"
        />
      </div>
    </Form>
  )
}
