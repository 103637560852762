import { DescriptionOutlined } from '@material-ui/icons'
import home from 'pages/Home/routes'
import news from 'pages/News/routes'
import pages from 'pages/Pages/routes'
import galleries from 'pages/Galleries/routes'
import eventCalendars from 'pages/EventCalendars/routes'
import trails from 'pages/Trails/routes'
import badges from 'pages/Badges/routes'
import authorityGroups from 'pages/AuthorityGroups/routes'
import committees from 'pages/Committees/routes'
import postcards from 'pages/Postcards/routes'
import publications from 'pages/Publications/routes'
import config from 'pages/Config/routes'
import admins from 'pages/Admins/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const menu = () =>
  [
    home().index,
    news().index,
    pages().index,
    galleries().index,
    eventCalendars().index,
    trails().index,
    badges().index,
    authorityGroups().index,
    committees().index,
    postcards().index,
    publications().index,
    config().edit,
    admins().index,
    {
      title: 'Rejestry',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
    profile().index,
  ]

export default menu
