import React from 'react'
import { makeStyles } from '@material-ui/styles'

const START_YEAR = 2022
const CURRENT_YEAR = new Date().getFullYear()

const useStyles = makeStyles({
  root: {
    fontSize: 12,
    marginTop: 18,
    color: '#999'
  }
})

export const Copyright = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {`PTTK Prochowice © ${START_YEAR === CURRENT_YEAR ? START_YEAR : `${START_YEAR} - ${CURRENT_YEAR}`}`}
    </div>
  )
}
