const schema = {
  endpoint: '/api/committees',
  resource: {
    definition: 'Committee-committee.read',
    properties: {
      title: {
        validate: ['required'],
      },
      stat: {}
    },
  },
  subresources: {
    position: {
      endpoint: '/api/committee_positions',
      definition: 'CommitteePosition-committeePosition.read',
      properties: {
        title: {
          width: 300,
        },
        persons: {
          width: 600,
          hint: 'Oddziel osoby przecinkiem, jeżeli występuje więcej niż 1'
        },
        stat: {},
      },
    }
  }
}

export default schema
