const schema = {
  endpoint: '/api/authority_groups',
  resource: {
    definition: 'AuthorityGroup-authorityGroup.read',
    properties: {
      title: {
        validate: ['required'],
      },
      stat: {}
    },
  },
  subresources: {
    position: {
      endpoint: '/api/authority_group_positions',
      definition: 'AuthorityGroupPosition-authorityGroupPosition.read',
      properties: {
        title: {
          width: 300,
          validate: ['required'],
        },
        persons: {
          width: 600,
          hint: 'Oddziel osoby przecinkiem, jeżeli występuje więcej niż 1'
        },
        stat: {},
      },
    }
  }
}

export default schema
