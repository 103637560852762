const schema = {
  endpoint: '/api/badges',
  resource: {
    definition: 'Badge-badge.read',
    properties: {
      title: {
        width: 400,
        validate: ['required'],
      },
      link: {
        width: 500,
        validate: ['required'],
      },
      type: {
        type: 'choice',
        choices: {
          sightseeing: 'Krajoznawcza',
          regional: 'Regionalna',
          touristic: 'Turystyczna',
        },
        validate: ['required'],
      },
      stat: {}
    },
  },
}

export default schema
