import photoSchema from './photo'

const schema = {
  endpoint: '/api/trails',
  resource: {
    definition: 'Trail-trail.read',
    properties: {
      title: {
        width: 500,
        validate: ['required'],
      },
      type: {
        type: 'choice',
        choices: {
          walking: 'Pieszy',
          bike: 'Rowerowy',
          motor: 'Motorowy',
        },
        validate: ['required'],
      },
      photo: {
        type: 'image',
        description: 'Znak',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      slug: {
        type: 'slug',
        routes: '/szlaki-turystyczne/:slug',
        hint: 'Automatycznie generowany z tytułu',
        width: 300
      },
      stat: {}
    },
  },
  subresources: {
    description: {
      endpoint: '/api/trail_descriptions',
      definition: 'TrailDescription-trailDescription.read',
      properties: {
        title: {
          width: 500,
        },
        text: {
          type: 'tinymce'
        },
        photo: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
        },
        stat: {},
      },
    }
  }
}

export default schema
