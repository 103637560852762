import fileSchema from './file'

const schema = {
  endpoint: '/api/event_calendars',
  resource: {
    definition: 'EventCalendar-eventCalendar.read',
    properties: {
      title: {
        validate: ['required'],
      },
      file: {
        type: 'file',
        description: 'Plik pdf',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      stat: {}
    },
  },
}

export default schema
