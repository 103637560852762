import React from 'react'
import { Paper } from 'components/Paper'

export const Home = () => {
  return (
    <Paper>
      Witaj w panelu administracyjnym strony PTTK Prochowice
    </Paper>
  )
}
