import photoSchema from './photo'

const schema = {
  endpoint: '/api/postcards',
  resource: {
    definition: 'Postcard-postcard.read',
    properties: {
      title: {
        width: 600
      },
      photo: {
        type: 'image',
        description: 'Pocztówka',
        endpoint: photoSchema.endpoint.single,
        thumbs: {
          endpoint: '/api/postcard_thumbs',
          names: {
            main: 'Kadr główny'
          }
        },
        validate: ['maxSize'],
      },
      stat: {}
    },
  },
}

export default schema
